import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { Helmet } from 'react-helmet';
import Reboot from './styles/Reboot';
import Header from './components/Header/Header';
import WishList from './components/WishList';
import Admin from './components/Admin';
import PhotoList from './components/PhotoList';
function App(props) {
    useEffect(function () { return Modal.setAppElement('#root'); }, []);
    return (<>
      <Reboot />
      <Helmet>
        <title>Vánoční radost</title>
      </Helmet>
      {props.pass ? (<Admin pass={props.pass}/>) : (<div>
          <div css={{ position: 'relative', zIndex: 1 }}>
            <Header />
          </div>
          <div css={{ position: 'relative', zIndex: 2, backgroundColor: 'white' }}>
            {props.isPhotos ? <PhotoList /> : <WishList />}
          </div>
        </div>)}
    </>);
}
export default App;
