var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import Loader from '../Loader';
import { wishFragment } from '../WishListItem';
import Alert from './Alert';
import { Group } from './Group';
import { Label } from './Label';
import { Input } from './Input';
import { Footer } from './Footer';
import { Button, CancelButton } from './Button';
var TAKE_WISH_MUTATION = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation TakeWish($id: ID!, $taker: WishTakerInput!) {\n    takeWish(id: $id, taker: $taker) {\n      success\n      wish {\n        ...wish\n      }\n      contact {\n        email\n        phoneNumber\n        facebook\n      }\n    }\n  }\n\n  ", "\n"], ["\n  mutation TakeWish($id: ID!, $taker: WishTakerInput!) {\n    takeWish(id: $id, taker: $taker) {\n      success\n      wish {\n        ...wish\n      }\n      contact {\n        email\n        phoneNumber\n        facebook\n      }\n    }\n  }\n\n  ", "\n"])), wishFragment);
function BookingForm(_a) {
    var _b;
    var id = _a.id, onCancel = _a.onCancel, onSuccessOrFailure = _a.onSuccessOrFailure;
    var _c = useState(''), firstName = _c[0], setFirstName = _c[1];
    var _d = useState(''), lastName = _d[0], setLastName = _d[1];
    var _e = useState(''), email = _e[0], setEmail = _e[1];
    var _f = useState(undefined), takeResult = _f[0], setTakeResult = _f[1];
    var _g = useMutation(TAKE_WISH_MUTATION), takeWish = _g[0], _h = _g[1], takeWishData = _h.data, isTaking = _h.loading;
    var submitDisabled = !firstName || !lastName || !email;
    function handleSubmit(event) {
        event.preventDefault();
        if (submitDisabled) {
            return;
        }
        takeWish({
            variables: {
                id: id,
                taker: {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                },
            },
        }).then(function (result) {
            setTakeResult(!result.data || !result.data.takeWish
                ? 'error'
                : result.data.takeWish.success
                    ? 'success'
                    : 'taken');
        });
    }
    if (takeResult === 'success') {
        return (<Alert backgroundColor="lightgreen" color="darkgreen" onClose={onSuccessOrFailure}>
        Děkujeme!
        {!!((_b = takeWishData === null || takeWishData === void 0 ? void 0 : takeWishData.takeWish) === null || _b === void 0 ? void 0 : _b.contact) && (<>
            <br />
            <br />
            <div>V případě dotazů kontaktujte Adél:</div>
            <div>
              E-mail:{' '}
              <a href={"mailto:" + takeWishData.takeWish.contact.email}>
                {takeWishData.takeWish.contact.email}
              </a>
            </div>
            <div>Telefon: {takeWishData.takeWish.contact.phoneNumber}</div>
            <div>
              <a href={takeWishData.takeWish.contact.facebook} target="_blank">
                Facebook
              </a>
            </div>
          </>)}
      </Alert>);
    }
    if (takeResult === 'error') {
        return (<Alert backgroundColor="mistyrose" color="darkred" onClose={onSuccessOrFailure}>
        Omlouváme se, došlo k neočekávané chybě.
        <br />
        Prosím, zavřete tento dialog a zkuste to znovu.
      </Alert>);
    }
    if (takeResult === 'taken') {
        return (<Alert backgroundColor="#fff3cd" color="#856404" onClose={onSuccessOrFailure}>
        Je nám líto, někdo byl rychlejší a přání Vám vyfoukl před nosem.
        <br />
        Věříme, že si vyberete jiné.
      </Alert>);
    }
    if (isTaking) {
        return <Loader>Rezervuji přání&hellip;</Loader>;
    }
    return (<form onSubmit={handleSubmit}>
      <Group>
        <Label htmlFor="firstName">Jméno</Label>
        <Input type="text" id="firstName" placeholder="Povinné" autoFocus value={firstName} onChange={function (e) { return setFirstName(e.currentTarget.value); }}/>
      </Group>
      <Group>
        <Label htmlFor="lastName">Příjmení</Label>
        <Input type="text" id="lastName" placeholder="Povinné" value={lastName} onChange={function (e) { return setLastName(e.currentTarget.value); }}/>
      </Group>
      <Group>
        <Label htmlFor="email">E-mail</Label>
        <Input type="email" id="email" placeholder="Povinné" value={email} onChange={function (e) { return setEmail(e.currentTarget.value); }}/>
      </Group>
      <Footer>
        <Button type="submit" disabled={submitDisabled}>
          Rezervovat
        </Button>
        <CancelButton type="button" onClick={onCancel}>
          Zrušit
        </CancelButton>
      </Footer>
    </form>);
}
export default BookingForm;
var templateObject_1;
