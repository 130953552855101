import styled from '@emotion/styled-base';
var Button = styled('button')(function (props) {
    var color = props.color === 'danger' ? '211, 47, 47' : '33, 150, 243';
    var hoverColor = props.color === 'danger' ? '198, 40, 40' : '21, 101, 192';
    return {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        boxSizing: 'border-box',
        outline: 0,
        border: props.outlined ? "1px solid rgba(" + color + ", 0.5)" : 0,
        margin: 0,
        cursor: 'pointer',
        userSelect: 'none',
        verticalAlign: 'middle',
        appearance: 'none',
        textDecoration: 'none',
        fontWeight: 500,
        fontSize: '0.875rem',
        lineHeight: '1.75',
        letterSpacing: '0.02857em',
        textTransform: 'uppercase',
        minWidth: '64px',
        padding: '6px 16px',
        borderRadius: '4px',
        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        color: props.outlined ? "rgb(" + color + ")" : 'rgb(255, 255, 255)',
        backgroundColor: props.outlined ? 'transparent' : "rgb(" + color + ")",
        boxShadow: props.outlined
            ? undefined
            : '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        marginRight: '0.5rem',
        ':disabled': {
            color: 'rgba(0, 0, 0, 0.26)',
            boxShadow: 'none',
            backgroundColor: props.outlined ? undefined : 'rgba(0, 0, 0, 0.12)',
            border: props.outlined ? '1px solid rgba(0, 0, 0, 0.12)' : undefined,
            pointerEvents: 'none',
            cursor: 'default',
        },
        ':hover, :active, :focus': {
            backgroundColor: props.outlined ? "rgba(" + color + ", 0.04)" : "rgb(" + hoverColor + ")",
            borderColor: props.outlined ? "rgb(" + color + ")" : undefined,
            outline: 'none',
        },
    };
});
export default Button;
