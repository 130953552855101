import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useSpring, animated } from 'react-spring';
import layer1 from './img/layer1.png';
import layer1HDPI from './img/layer1@2x.png';
import layer2 from './img/layer2.png';
import layer2HDPI from './img/layer2@2x.png';
import layer3 from './img/layer3.png';
import layer3HDPI from './img/layer3@2x.png';
import layer4 from './img/layer4.png';
import layer4HDPI from './img/layer4@2x.png';
import layer5 from './img/layer5.png';
import layer5HDPI from './img/layer5@2x.png';
import layer6 from './img/layer6.png';
import layer6HDPI from './img/layer6@2x.png';
import logo from './img/logo.png';
import logoHDPI from './img/logo@2x.png';
import logoSmall from './img/logo-small.png';
import logoSmallHDPI from './img/logo-small@2x.png';
import snowflakes from './img/snowflakes.png';
import snowflakesHDPI from './img/snowflakes@2x.png';
import snowflakes2 from './img/snowflakes2.png';
import snowflakes2HDPI from './img/snowflakes2@2x.png';
import house from './img/house.png';
import houseHDPI from './img/house@2x.png';
var Layer = styled(animated.div)({
    backgroundPosition: 'bottom center',
    backgroundSize: 'auto 700px',
    backgroundRepeat: 'repeat-x',
    width: '100%',
    height: '700px',
    position: 'fixed',
});
var getTrans = function (speed) { return function (y) { return "translate3d(0px," + -y * speed + "px,0)"; }; };
function Header() {
    var _a = useSpring(function () { return ({
        y: 0,
        config: { mass: 0, tension: 0, friction: 0 },
    }); }), props = _a[0], set = _a[1];
    useEffect(function () {
        function handleScroll() {
            set({ y: window.scrollY });
        }
        window.addEventListener('scroll', handleScroll);
        set({ y: window.scrollY });
        return function () {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [set]);
    return (<div css={{
        height: '700px',
        backgroundColor: '#CCE3E8',
    }}>
      <Layer style={{
        transform: props.y.interpolate(getTrans(0.1)),
    }} css={{
        backgroundImage: "url('" + layer6 + "')",
        '@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)': {
            backgroundImage: "url('" + layer6HDPI + "')",
        },
    }}/>
      <Layer style={{
        transform: props.y.interpolate(getTrans(0.15)),
    }} css={{
        backgroundImage: "url('" + house + "')",
        '@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)': {
            backgroundImage: "url('" + houseHDPI + "')",
        },
    }}/>
      <Layer style={{
        transform: props.y.interpolate(getTrans(0.2)),
    }} css={{
        backgroundImage: "url('" + layer5 + "')",
        '@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)': {
            backgroundImage: "url('" + layer5HDPI + "')",
        },
    }}/>
      <Layer style={{
        transform: props.y.interpolate(getTrans(0.3)),
    }} css={{
        backgroundImage: "url('" + layer4 + "')",
        '@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)': {
            backgroundImage: "url('" + layer4HDPI + "')",
        },
    }}/>
      <Layer style={{
        transform: props.y.interpolate(getTrans(0.4)),
    }} css={{
        backgroundImage: "url('" + layer3 + "')",
        '@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)': {
            backgroundImage: "url('" + layer3HDPI + "')",
        },
    }}/>
      <Layer style={{
        transform: props.y.interpolate(getTrans(-0.05)),
    }} css={{
        backgroundImage: "url('" + snowflakes + "')",
        top: '-100px',
        '@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)': {
            backgroundImage: "url('" + snowflakesHDPI + "')",
        },
    }}/>
      <Layer style={{
        transform: props.y.interpolate(getTrans(-0.15)),
    }} css={{
        backgroundImage: "url('" + snowflakes2 + "')",
        top: '-100px',
        '@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)': {
            backgroundImage: "url('" + snowflakes2HDPI + "')",
        },
    }}/>
      <Layer style={{
        transform: props.y.interpolate(getTrans(0.3)),
    }} css={{
        backgroundImage: "url('" + logoSmall + "')",
        '@media (min-width: 550px)': {
            backgroundImage: "url('" + logo + "')",
        },
        '@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)': {
            backgroundImage: "url('" + logoSmallHDPI + "')",
        },
        '@media (min-width: 550px) and (-webkit-min-device-pixel-ratio: 1.5), (min-width: 550px) and (min-resolution: 144dpi)': {
            backgroundImage: "url('" + logoHDPI + "')",
        },
    }}/>
      <Layer style={{
        transform: props.y.interpolate(getTrans(0.9)),
    }} css={{
        backgroundImage: "url('" + layer2 + "')",
        '@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)': {
            backgroundImage: "url('" + layer2HDPI + "')",
        },
    }}/>
      <Layer css={{
        position: 'absolute',
        backgroundImage: "url('" + layer1 + "')",
        '@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)': {
            backgroundImage: "url('" + layer1HDPI + "')",
        },
    }}/>
    </div>);
}
export default Header;
