var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useState } from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Loader from '../Loader';
import Button from './Button';
import { FormGroup, Input, Label } from './Form';
import { useDropzone } from 'react-dropzone';
var PHOTO_LIST_QUERY = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query PhotoList {\n    photos {\n      _id\n      year\n      url\n    }\n  }\n"], ["\n  query PhotoList {\n    photos {\n      _id\n      year\n      url\n    }\n  }\n"])));
var DELETE_PHOTO_MUTATION = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  mutation DeletePhoto($id: ID!, $password: String!) {\n    deletePhoto(id: $id, password: $password)\n  }\n"], ["\n  mutation DeletePhoto($id: ID!, $password: String!) {\n    deletePhoto(id: $id, password: $password)\n  }\n"])));
export default function Photos(_a) {
    var _b;
    var pass = _a.pass;
    var _c = useState(new Date().getFullYear() - 1), year = _c[0], setYear = _c[1];
    var _d = useState([]), photos = _d[0], setPhotos = _d[1];
    var _e = useState(false), uploading = _e[0], setUploading = _e[1];
    var _f = useQuery(PHOTO_LIST_QUERY), data = _f.data, error = _f.error, loading = _f.loading, refetch = _f.refetch;
    var deletePhoto = useMutation(DELETE_PHOTO_MUTATION, {
        refetchQueries: [{ query: PHOTO_LIST_QUERY }],
    })[0];
    var onDrop = useCallback(function (acceptedFiles) {
        setPhotos(acceptedFiles);
    }, []);
    var _g = useDropzone({
        onDrop: onDrop,
        accept: {
            'image/jpeg': [],
            'image/png': [],
        },
    }), getRootProps = _g.getRootProps, getInputProps = _g.getInputProps, isDragActive = _g.isDragActive;
    if (error) {
        return <h2>Nastala neočekávaná chyba ☹</h2>;
    }
    if (uploading || loading) {
        return <Loader>Nahrávám fotky&hellip;</Loader>;
    }
    var years = Object.values((_b = data === null || data === void 0 ? void 0 : data.photos.reduce(function (acc, cur) {
        if (!acc[cur.year]) {
            acc[cur.year] = { year: cur.year, photos: [] };
        }
        acc[cur.year].photos.push(cur);
        return acc;
    }, {})) !== null && _b !== void 0 ? _b : {}).sort(function (a, b) { return b.year - a.year; });
    return (<>
      <h2>Nahrát nové fotky</h2>
      <div css={{ marginBottom: '2rem' }}>
        <FormGroup>
          <Label htmlFor="year">Fotky za rok</Label>
          <Input id="year" type="number" value={year} onChange={function (e) { return setYear(parseInt(e.currentTarget.value, 10)); }}/>
        </FormGroup>
        <div {...getRootProps()} css={{
        marginBottom: '1rem',
        padding: '1rem',
        border: isDragActive ? '1px solid red' : '1px dashed gray',
        borderRadius: '4px',
    }}>
          <input {...getInputProps()}/>
          {isDragActive ? (<>Teď už je stačí jen pustit...</>) : (<>Klikni sem pro výběr fotek nebo je sem přetáhni...</>)}
          {photos.length > 0 && (<div css={{ marginTop: '1rem' }}>
              <b>Vybrané fotky: {photos.length}</b>
            </div>)}
        </div>
        <Button type="submit" disabled={photos.length === 0} onClick={handleSubmit}>
          Nahrát
        </Button>
      </div>

      {years.map(function (_a) {
        var year = _a.year, photos = _a.photos;
        return (<div key={year}>
          <h2>{year}</h2>
          <div css={{ display: 'flex', flexWrap: 'wrap' }}>
            {photos.map(function (photo, index) { return (<div key={photo._id} css={{
            marginRight: (index + 1) % 3 === 0 ? 0 : '1rem',
            marginBottom: '1rem',
            textAlign: 'center',
        }}>
                <a href={photo.url} target="_blank" css={{
            display: 'block',
            width: '300px',
            height: '225px',
        }}>
                  <img src={photo.url} alt="" css={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
                </a>
                <button css={{
            border: 'none',
            background: 'none',
            color: 'red',
            padding: '0.25rem',
            cursor: 'pointer',
            outline: 'none',
            ':hover, :active, :focus': {
                outline: 'none',
            },
        }} onClick={function () { return handleDelete(photo._id); }}>
                  Smazat
                </button>
              </div>); })}
          </div>
        </div>);
    })}
    </>);
    function handleSubmit() {
        return __awaiter(this, void 0, void 0, function () {
            var url, formData, _i, photos_1, photo, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (photos.length === 0) {
                            return [2 /*return*/];
                        }
                        setUploading(true);
                        url = "/photos/upload/" + year + "?password=" + pass;
                        formData = new FormData();
                        for (_i = 0, photos_1 = photos; _i < photos_1.length; _i++) {
                            photo = photos_1[_i];
                            formData.append('photos', photo);
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, fetch(url, {
                                method: 'POST',
                                body: formData,
                            })];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        setUploading(false);
                        setPhotos([]);
                        refetch();
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleDelete(id) {
        deletePhoto({ variables: { id: id, password: pass } });
    }
}
var templateObject_1, templateObject_2;
