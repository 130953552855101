import React from 'react';
import { CancelButton } from './Button';
function Alert(props) {
    return (<div css={{
        textAlign: 'center',
    }}>
      <h2 css={{
        padding: '32px 0',
        textAlign: 'center',
        borderRadius: '16px',
        backgroundColor: props.backgroundColor,
        color: props.color,
        marginBottom: '16px',
    }}>
        {props.children}
      </h2>
      <CancelButton type="button" onClick={props.onClose}>
        Zavřít
      </CancelButton>
    </div>);
}
export default Alert;
