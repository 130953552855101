var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Loader from '../Loader';
import { wishFragment } from '../WishListItem';
import Button from './Button';
import { FormGroup, Input, Label, Textarea } from './Form';
var WISH_LIST_QUERY = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query WishList {\n    wishes {\n      ...wish\n      takenDate\n      takenBy {\n        firstName\n        lastName\n        email\n      }\n    }\n  }\n\n  ", "\n"], ["\n  query WishList {\n    wishes {\n      ...wish\n      takenDate\n      takenBy {\n        firstName\n        lastName\n        email\n      }\n    }\n  }\n\n  ", "\n"])), wishFragment);
var RELEASE_WISH_MUTATION = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  mutation ReleaseWish($id: ID!, $password: String!) {\n    releaseWish(id: $id, password: $password) {\n      ...wish\n      takenDate\n      takenBy {\n        firstName\n        lastName\n        email\n      }\n    }\n  }\n\n  ", "\n"], ["\n  mutation ReleaseWish($id: ID!, $password: String!) {\n    releaseWish(id: $id, password: $password) {\n      ...wish\n      takenDate\n      takenBy {\n        firstName\n        lastName\n        email\n      }\n    }\n  }\n\n  ", "\n"])), wishFragment);
var DELETE_WISH_MUTATION = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  mutation DeleteWish($id: ID!, $password: String!) {\n    deleteWish(id: $id, password: $password)\n  }\n"], ["\n  mutation DeleteWish($id: ID!, $password: String!) {\n    deleteWish(id: $id, password: $password)\n  }\n"])));
var DELETE_ALL_WISHES_MUTATION = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  mutation DeleteAllWishes($password: String!) {\n    deleteAllWishes(password: $password)\n  }\n"], ["\n  mutation DeleteAllWishes($password: String!) {\n    deleteAllWishes(password: $password)\n  }\n"])));
var ADD_WISH_MUTATION = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  mutation AddWish($person: String!, $text: String!, $link: String, $password: String!) {\n    addWish(person: $person, text: $text, link: $link, password: $password) {\n      ...wish\n      takenDate\n      takenBy {\n        firstName\n        lastName\n        email\n      }\n    }\n  }\n\n  ", "\n"], ["\n  mutation AddWish($person: String!, $text: String!, $link: String, $password: String!) {\n    addWish(person: $person, text: $text, link: $link, password: $password) {\n      ...wish\n      takenDate\n      takenBy {\n        firstName\n        lastName\n        email\n      }\n    }\n  }\n\n  ", "\n"])), wishFragment);
var EDIT_WISH_MUTATION = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  mutation EditWish($id: ID!, $person: String!, $text: String!, $link: String, $password: String!) {\n    editWish(id: $id, person: $person, text: $text, link: $link, password: $password) {\n      ...wish\n      takenDate\n      takenBy {\n        firstName\n        lastName\n        email\n      }\n    }\n  }\n\n  ", "\n"], ["\n  mutation EditWish($id: ID!, $person: String!, $text: String!, $link: String, $password: String!) {\n    editWish(id: $id, person: $person, text: $text, link: $link, password: $password) {\n      ...wish\n      takenDate\n      takenBy {\n        firstName\n        lastName\n        email\n      }\n    }\n  }\n\n  ", "\n"])), wishFragment);
export default function Wishes(_a) {
    var pass = _a.pass;
    var _b = useQuery(WISH_LIST_QUERY), data = _b.data, error = _b.error, loading = _b.loading;
    var releaseWish = useMutation(RELEASE_WISH_MUTATION)[0];
    var deleteWish = useMutation(DELETE_WISH_MUTATION, {
        refetchQueries: [{ query: WISH_LIST_QUERY }],
    })[0];
    var deleteAllWishes = useMutation(DELETE_ALL_WISHES_MUTATION, { refetchQueries: [{ query: WISH_LIST_QUERY }] })[0];
    var _c = useMutation(ADD_WISH_MUTATION, { refetchQueries: [{ query: WISH_LIST_QUERY }] }), addWish = _c[0], adding = _c[1].loading;
    var _d = useMutation(EDIT_WISH_MUTATION), editWish = _d[0], editing = _d[1].loading;
    var _e = useState(''), editedId = _e[0], setEditedId = _e[1];
    if (error) {
        return <h2>Nastala neočekávaná chyba ☹</h2>;
    }
    if (loading && !data) {
        return <Loader>Nahrávám přání&hellip;</Loader>;
    }
    var wishes = (data && data.wishes) || [];
    var takenWishes = wishes.filter(function (wish) { return wish.isTaken; });
    var freeWishes = wishes.filter(function (wish) { return !wish.isTaken; });
    return (<>
      <div css={{ marginBottom: '2rem' }}>
        <Button color="danger" onClick={handleDeleteAll}>
          Smazat všechny dárky
        </Button>
      </div>
      <h2 css={{ marginTop: '2rem' }}>Přidat nový dárek</h2>
      <WishForm saving={adding} onSave={handleAdd}/>
      <h2 css={{ marginTop: '2rem' }}>Seznam dárků bez rezervace</h2>
      {freeWishes.length === 0 && <h3>Kde nic tu nic</h3>}
      {freeWishes.map(function (wish) {
        return editedId === wish._id ? (<WishForm key={wish._id} saving={editing} wish={wish} onCancel={function () { return setEditedId(''); }} onSave={handleSave}/>) : (<WishListItem key={wish._id} wish={wish} onDelete={handleDeleteWish} onEdit={function () { return setEditedId(wish._id); }}/>);
    })}
      <h2 css={{ marginTop: '2rem' }}>Seznam dárků s rezervací</h2>
      {takenWishes.length === 0 && <h3>Kde nic tu nic</h3>}
      {takenWishes.map(function (wish) {
        return editedId === wish._id ? (<WishForm key={wish._id} saving={editing} wish={wish} onCancel={function () { return setEditedId(''); }} onSave={handleSave}/>) : (<WishListItem key={wish._id} wish={wish} onDelete={handleDeleteWish} onEdit={function () { return setEditedId(wish._id); }} onRelease={handleReleaseWish}/>);
    })}
    </>);
    function handleReleaseWish(id) {
        if (window.confirm('Opravdu zrušit rezervaci?')) {
            releaseWish({ variables: { id: id, password: pass } });
        }
    }
    function handleDeleteWish(id) {
        if (window.confirm('Opravdu SMAZAT dárek?')) {
            deleteWish({ variables: { id: id, password: pass } });
        }
    }
    function handleDeleteAll() {
        if (window.confirm('Opravdu SMAZAT úplně VŠECHNY dárky?')) {
            deleteAllWishes({ variables: { password: pass } });
        }
    }
    function handleAdd(data) {
        return __awaiter(this, void 0, void 0, function () {
            var e_1, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, addWish({
                                variables: {
                                    person: data.person,
                                    text: data.text,
                                    link: data.link || null,
                                    password: pass,
                                },
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        error_1 = e_1;
                        alert("Nastala chyba: " + (error_1.message || error_1.toString()));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    }
    function handleSave(data) {
        return __awaiter(this, void 0, void 0, function () {
            var e_2, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, editWish({
                                variables: {
                                    id: data.id,
                                    person: data.person,
                                    text: data.text,
                                    link: data.link || null,
                                    password: pass,
                                },
                            })];
                    case 1:
                        _a.sent();
                        setEditedId('');
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        error_2 = e_2;
                        alert("Nastala chyba: " + (error_2.message || error_2.toString()));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    }
}
function WishListItem(props) {
    return (<div css={{
        borderRadius: '8px',
        boxShadow: '1px 1px 4px silver',
        padding: '1rem',
        marginBottom: '1.5rem',
    }}>
      <div css={{ marginBottom: '0.5rem', fontSize: '1.5rem' }}>
        {props.wish.text.split('\n').map(function (item, index) { return (<React.Fragment key={index}>
            {index > 0 && <br />}
            {item}
          </React.Fragment>); })}
      </div>
      <div css={{ marginBottom: '0.5rem' }}>
        {props.wish.link && (<div css={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            <a href={props.wish.link} target="_blank" rel="noopener noreferrer">
              {props.wish.link}
            </a>
          </div>)}
      </div>
      <div css={{
        fontStyle: 'italic',
        marginBottom: '1rem',
    }}>
        {props.wish.person}
      </div>
      {props.wish.takenBy && props.wish.takenDate && (<div css={{ marginBottom: '1rem' }}>
          <h4>Rezervoval(a)</h4>
          <div>
            {props.wish.takenBy.firstName} {props.wish.takenBy.lastName}
            <br />
            {props.wish.takenBy.email}
            <br />
            {new Date(props.wish.takenDate).toLocaleString('cs')}
          </div>
        </div>)}
      <div>
        {props.onRelease && (<Button type="button" outlined onClick={function () { var _a; return (_a = props.onRelease) === null || _a === void 0 ? void 0 : _a.call(props, props.wish._id); }}>
            Zrušit rezervaci
          </Button>)}
        {props.onEdit && (<Button type="button" outlined onClick={function () { var _a; return (_a = props.onEdit) === null || _a === void 0 ? void 0 : _a.call(props, props.wish._id); }}>
            Upravit dárek
          </Button>)}
        {props.onDelete && (<Button color="danger" outlined type="button" onClick={function () { var _a; return (_a = props.onDelete) === null || _a === void 0 ? void 0 : _a.call(props, props.wish._id); }}>
            Smazat dárek
          </Button>)}
      </div>
    </div>);
}
function WishForm(props) {
    var _a, _b, _c;
    var _d = useState(((_a = props.wish) === null || _a === void 0 ? void 0 : _a.person) || ''), person = _d[0], setPerson = _d[1];
    var _e = useState(((_b = props.wish) === null || _b === void 0 ? void 0 : _b.text) || ''), text = _e[0], setText = _e[1];
    var _f = useState(((_c = props.wish) === null || _c === void 0 ? void 0 : _c.link) || ''), link = _f[0], setLink = _f[1];
    return (<div css={{
        borderRadius: '8px',
        boxShadow: '1px 1px 4px silver',
        padding: '1rem',
        marginBottom: '1.5rem',
    }}>
      <FormGroup>
        <Label htmlFor="text">Text</Label>
        <Textarea id="text" rows={2} value={text} onChange={function (e) { return setText(e.currentTarget.value); }}/>
      </FormGroup>
      <FormGroup>
        <Label htmlFor="person">Pro koho</Label>
        <Input id="person" type="text" value={person} onChange={function (e) { return setPerson(e.currentTarget.value); }}/>
      </FormGroup>
      <FormGroup>
        <Label htmlFor="link">Odkaz (nepovinné)</Label>
        <Input id="link" type="text" value={link} onChange={function (e) { return setLink(e.currentTarget.value); }}/>
      </FormGroup>
      <div>
        <Button disabled={!text || !person || props.saving} type="button" onClick={function () {
        var _a;
        props.onSave({
            id: (_a = props.wish) === null || _a === void 0 ? void 0 : _a._id,
            text: text,
            link: link,
            person: person,
        });
        setPerson('');
        setText('');
        setLink('');
    }}>
          {props.wish ? 'Uložit' : props.saving ? 'Ukládání' : 'Přidat'}
        </Button>
        {props.onCancel && (<Button type="button" outlined onClick={function () { var _a; return (_a = props.onCancel) === null || _a === void 0 ? void 0 : _a.call(props); }}>
            Zrušit
          </Button>)}
      </div>
    </div>);
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
