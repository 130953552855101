var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { RECAPTCHA_SITE_KEY } from '../consts';
import { Button } from './BookingForm/Button';
var CONTACT_QUERY = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query Contact($response: String!) {\n    contact(response: $response) {\n      email\n      phoneNumber\n      facebook\n    }\n  }\n"], ["\n  query Contact($response: String!) {\n    contact(response: $response) {\n      email\n      phoneNumber\n      facebook\n    }\n  }\n"])));
function Contact() {
    var _a = useLazyQuery(CONTACT_QUERY), fetch = _a[0], _b = _a[1], data = _b.data, error = _b.error, loading = _b.loading;
    if (error) {
        return <div>Nastala neočekávaná chyba ☹</div>;
    }
    if (loading) {
        return <div>Nahrávám kontakt&hellip;</div>;
    }
    if (data) {
        return (<div>
        <div>
          E-mail: <a href={"mailto:" + data.contact.email}>{data.contact.email}</a>
        </div>
        <div>Telefon: {data.contact.phoneNumber}</div>
        <div><a href={data.contact.facebook} target="_blank">Facebook</a></div>
      </div>);
    }
    return (<>
      <script src={"https://www.google.com/recaptcha/api.js?render=" + RECAPTCHA_SITE_KEY}/>
      <Button type="button" onClick={handleClick}>
        Zobrazit kontakt
      </Button>
    </>);
    function handleClick() {
        window.grecaptcha.ready(function () {
            window.grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'submit' }).then(function (token) {
                fetch({
                    variables: {
                        response: token,
                    },
                });
            });
        });
    }
}
export default Contact;
var templateObject_1;
