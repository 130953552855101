var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Loader from './Loader';
import { LinkButton } from './BookingForm/Button';
var PHOTO_LIST_QUERY = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query PhotoList {\n    photos {\n      _id\n      year\n      url\n    }\n  }\n"], ["\n  query PhotoList {\n    photos {\n      _id\n      year\n      url\n    }\n  }\n"])));
function WishList() {
    var _a;
    var _b = useQuery(PHOTO_LIST_QUERY), data = _b.data, error = _b.error, loading = _b.loading;
    if (error) {
        return <h2>Nastala neočekávaná chyba ☹</h2>;
    }
    if (loading) {
        return <Loader>Nahrávám fotky&hellip;</Loader>;
    }
    var photos = (data && data.photos) || [];
    var years = Object.values((_a = photos.reduce(function (acc, cur) {
        if (!acc[cur.year]) {
            acc[cur.year] = { year: cur.year, photos: [] };
        }
        acc[cur.year].photos.push(cur);
        return acc;
    }, {})) !== null && _a !== void 0 ? _a : {}).sort(function (a, b) { return b.year - a.year; });
    return (<div css={{ padding: '0 16px', maxWidth: '1440px', margin: '0 auto' }}>
      <div css={{ textAlign: 'center', marginBottom: '2rem' }}>
        <LinkButton href="/">Zpět na seznam přání</LinkButton>
      </div>

      {photos.length === 0 && (<h2 css={{ textAlign: 'center', paddingBottom: '100px' }}>Již brzy&hellip;</h2>)}

      {years.map(function (_a) {
        var year = _a.year, photos = _a.photos;
        return (<div key={year} css={{ marginBottom: '2rem' }}>
          <h1>{year}</h1>
          <div css={{ display: 'flex', flexWrap: 'wrap' }}>
            {photos.map(function (photo) { return (<div key={photo._id} css={{
            flexGrow: 0,
            flexBasis: '100%',
            maxWidth: '100%',
            paddingRight: '1rem',
            paddingBottom: '1rem',
            '@media (min-width: 420px)': {
                flexBasis: '50%',
                maxWidth: '50%',
            },
            '@media (min-width: 800px)': {
                flexBasis: '25%',
                maxWidth: '25%',
            },
        }}>
                <a href={photo.url} target="_blank" css={{
            display: 'block',
        }}>
                  <div style={{ backgroundImage: "url('" + photo.url + "')" }} css={{
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            height: 0,
            paddingTop: '75%',
        }}/>
                </a>
              </div>); })}
          </div>
        </div>);
    })}
    </div>);
}
export default WishList;
var templateObject_1;
