var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Loader from './Loader';
import WishListItem, { wishFragment } from './WishListItem';
import BookingModal from './BookingModal';
import Contact from './Contact';
import { LinkButton } from './BookingForm/Button';
var WISH_LIST_QUERY = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query WishList {\n    wishes {\n      ...wish\n    }\n  }\n\n  ", "\n"], ["\n  query WishList {\n    wishes {\n      ...wish\n    }\n  }\n\n  ", "\n"])), wishFragment);
function WishList() {
    var _a = useQuery(WISH_LIST_QUERY), data = _a.data, error = _a.error, loading = _a.loading;
    var _b = useState(null), bookingWish = _b[0], setBookingWish = _b[1];
    if (error) {
        return <h2>Nastala neočekávaná chyba ☹</h2>;
    }
    if (loading) {
        return <Loader>Nahrávám přání&hellip;</Loader>;
    }
    var wishes = (data && data.wishes) || [];
    if (wishes.length === 0) {
        return <h2 css={{ textAlign: 'center', paddingBottom: '100px' }}>Již brzy&hellip;</h2>;
    }
    return (<>
      <div css={{ padding: '0 16px', maxWidth: '1440px', margin: '0 auto' }}>
        <div css={{ textAlign: 'center', marginBottom: '2rem' }}>
          <LinkButton href="/fotky">Zobrazit fotogalerii</LinkButton>
        </div>

        {__spreadArrays(wishes).sort(function (a, b) { return Number(a.isTaken) - Number(b.isTaken); })
        .map(function (wish) { return (<WishListItem key={wish._id} wish={wish} onBookingOpen={setBookingWish}/>); })}

        <div css={{ padding: '3rem 0', display: 'flex', justifyContent: 'center' }}>
          <Contact />
        </div>
      </div>
      <BookingModal onClose={function () { return setBookingWish(null); }} onDidBook={function () { return setBookingWish(null); }} wish={bookingWish}/>
    </>);
}
export default WishList;
var templateObject_1;
