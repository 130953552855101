import React from 'react';
import { keyframes } from '@emotion/core';
var animation = keyframes({
    '0%': {
        transform: 'scale(0.95)',
    },
    '5%': {
        transform: 'scale(1.1)',
    },
    '39%': {
        transform: 'scale(0.85)',
    },
    '45%': {
        transform: 'scale(1)',
    },
    '60%': {
        transform: 'scale(0.95)',
    },
    '100%': {
        transform: 'scale(0.9)',
    },
});
function Loader(_a) {
    var children = _a.children;
    return (<div css={{
        textAlign: 'center',
    }}>
      <div css={{
        display: 'block',
        position: 'relative',
        width: '64px',
        height: '64px',
        transform: 'rotate(45deg)',
        transformOrigin: '32px 32px',
        margin: '0 auto',
        '& div': {
            top: '23px',
            left: '19px',
            position: 'absolute',
            width: '26px',
            height: '26px',
            background: '#913c46',
            animation: animation + " 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1)",
        },
        '& div::after, & div::before': {
            content: '" "',
            position: 'absolute',
            display: 'block',
            width: '26px',
            height: '26px',
            background: '#913c46',
        },
        '& div::before': {
            left: '-17px',
            borderRadius: '50% 0 0 50%',
        },
        '& div::after': {
            top: '-17px',
            borderRadius: '50% 50% 0 0',
        },
    }}>
        <div />
      </div>
      <h2>{children}</h2>
    </div>);
}
export default Loader;
