var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import gql from 'graphql-tag';
function WishListItem(_a) {
    var wish = _a.wish, onBookingOpen = _a.onBookingOpen;
    return (<div css={{
        backgroundColor: wish.isTaken ? 'whitesmoke' : 'honeydew',
        borderRadius: '8px',
        boxShadow: '1px 1px 4px silver',
        color: wish.isTaken ? 'darkgray' : undefined,
        fontSize: '1.5em',
        padding: '0.5em 1em',
        marginBottom: '1.5em',
        '@media screen and (min-width: 768px)': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    }}>
      <div css={{ paddingRight: '12px', overflow: 'hidden' }}>
        {wish.text.split('\n').map(function (item, index) { return (<React.Fragment key={index}>
            {index > 0 && <br />}
            {item}
          </React.Fragment>); })}
        {wish.link && (<div css={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            <a href={wish.link} target="_blank" rel="noopener noreferrer">
              {wish.link}
            </a>
          </div>)}
        <div css={{
        fontSize: '0.8em',
        fontStyle: 'italic',
    }}>
          {wish.person}
        </div>
      </div>
      <div css={{
        '@media screen and (max-width: 767px)': {
            marginTop: '8px',
            textAlign: 'center',
        },
    }}>
        {wish.isTaken ? (<div css={{ color: 'darkslategray' }}>Rezervováno</div>) : (<button type="button" onClick={function () { return onBookingOpen(wish); }} css={{
        backgroundColor: 'lightgreen',
        boxShadow: '1px 1px 5px green',
        border: 'none',
        borderRadius: '4px',
        color: 'darkgreen',
        cursor: 'pointer',
        padding: '2px 12px',
        textAlign: 'center',
        '&:hover': {
            backgroundColor: 'limegreen',
        },
        '&:focus': {
            outline: 'none',
        },
    }}>
            Rezervovat
          </button>)}
      </div>
    </div>);
}
export default WishListItem;
export var wishFragment = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment wish on Wish {\n    _id\n    person\n    text\n    link\n    isTaken\n  }\n"], ["\n  fragment wish on Wish {\n    _id\n    person\n    text\n    link\n    isTaken\n  }\n"])));
var templateObject_1;
