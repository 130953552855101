import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
export default function configureClient(config) {
    if (config === void 0) { config = {}; }
    var cache = new InMemoryCache();
    var errorLink = onError(function (_a) {
        var graphQLErrors = _a.graphQLErrors, networkError = _a.networkError, operation = _a.operation;
        if (graphQLErrors) {
            var formattedGraphQLErrors = graphQLErrors.map(function (_a) {
                var message = _a.message, locations = _a.locations, path = _a.path;
                return "[GraphQL error]: Message: " + message + ", Operation: " + operation.operationName + ", Locations: " + (locations &&
                    locations
                        .map(function (location) { return location.line + ":" + location.column; })
                        .join(', ')) + ", Path: " + path;
            });
            if (process.env.BUILD_TARGET === 'server') {
                formattedGraphQLErrors.forEach(function (error) { return console.error(error, operation); });
            }
        }
        if (networkError) {
            console.error("[Network error]: " + networkError);
        }
    });
    var terminatingLink = createHttpLink({
        uri: process.env.GRAPHQL_URI,
    });
    return new ApolloClient({
        link: ApolloLink.from([errorLink, terminatingLink]),
        cache: config.initialState ? cache.restore(config.initialState) : cache,
        ssrMode: process.env.BUILD_TARGET === 'server',
    });
}
