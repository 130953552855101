import styled from '@emotion/styled';
export var Button = styled.button({
    backgroundColor: 'lightgreen',
    border: 'none',
    borderRadius: '4px',
    boxShadow: '1px 1px 5px green',
    color: 'darkgreen',
    cursor: 'pointer',
    display: 'inline-block',
    textAlign: 'center',
    fontSize: '1.2em',
    padding: '2px 16px',
    marginLeft: '12px',
    '&:hover': {
        backgroundColor: 'limegreen',
        color: 'darkgreen',
        textDecoration: 'none',
    },
    '&:disabled': {
        opacity: 0.5,
        cursor: 'default',
        '&:hover': {
            backgroundColor: 'lightgreen',
        },
    },
    '&:focus': {
        outline: 'none',
    },
});
export var CancelButton = styled(Button)({
    backgroundColor: 'white',
    boxShadow: '1px 1px 5px darkgray',
    color: 'black',
    '&:hover': {
        backgroundColor: 'lightgray',
    },
});
export var LinkButton = Button.withComponent('a');
