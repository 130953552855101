var _a;
import 'core-js/stable';
import 'isomorphic-fetch';
import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import ReactDOM from 'react-dom';
import configureClient from './api/apolloClient';
import App from './App';
var apolloClient = configureClient({
    initialState: window.__APOLLO_STATE__,
});
var queryList = window.location.search
    .substring(1)
    .split('&')
    .map(function (x) { return x.split('='); });
var pass = (_a = queryList.find(function (q) { return q[0] === 'pass'; })) === null || _a === void 0 ? void 0 : _a[1];
ReactDOM.hydrate(<ApolloProvider client={apolloClient}>
    <App pass={pass} isPhotos={window.location.pathname === '/fotky'}/>
  </ApolloProvider>, document.getElementById('root'));
