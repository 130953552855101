import styled from '@emotion/styled-base';
export var FormGroup = styled('div')({
    marginBottom: '1rem',
});
export var Label = styled('label')({
    display: 'block',
});
export var Input = styled('input')({
    width: '100%',
    borderRadius: '4px',
    border: '1px solid silver',
    padding: '0.5rem',
});
export var Textarea = styled('textarea')({
    width: '100%',
    borderRadius: '4px',
    border: '1px solid silver',
    padding: '0.5rem',
});
