import React from 'react';
import Modal from 'react-modal';
import { ClassNames } from '@emotion/core';
import BookingForm from './BookingForm/BookingForm';
function BookingModal(_a) {
    var onClose = _a.onClose, onDidBook = _a.onDidBook, wish = _a.wish;
    return (<ClassNames>
      {function (_a) {
        var css = _a.css;
        return (<Modal isOpen={Boolean(wish)} onRequestClose={onClose} className={css({
            width: '100%',
            maxWidth: '600px',
            margin: '100px auto 0',
            padding: '24px',
            background: 'white',
            border: '1px solid lightgray',
            borderRadius: '8px',
            zIndex: 3,
        })} overlayClassName={css({
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            overflow: 'auto',
            paddingBottom: '32px',
            zIndex: 4,
        })} bodyOpenClassName={css({ overflow: 'hidden' })}>
          <h1>Rezervace přání</h1>
          <h3 className={css({
            position: 'relative',
            paddingLeft: '16px',
            marginBottom: '16px',
            fontWeight: 'normal',
            '&::before': {
                content: '""',
                position: 'absolute',
                top: '0',
                left: '0',
                width: '5px',
                height: '100%',
                backgroundColor: 'lightgray',
            },
        })}>
            {wish &&
            wish.text.split('\n').map(function (item, index) { return (<React.Fragment key={index}>
                  {index > 0 && <br />}
                  {item}
                </React.Fragment>); })}
            <div className={css({ fontStyle: 'italic', fontSize: '0.8em' })}>
              {wish && wish.person}
            </div>
          </h3>
          <BookingForm id={wish ? wish._id : ''} onCancel={onClose} onSuccessOrFailure={onDidBook}/>
        </Modal>);
    }}
    </ClassNames>);
}
export default BookingModal;
