import styled from '@emotion/styled';
export var Input = styled.input({
    display: 'block',
    width: '100%',
    border: '1px solid silver',
    borderRadius: '4px',
    outline: 'none',
    padding: '4px 8px',
    '&:focus': {
        borderColor: 'rgb(59, 153, 252)',
        boxShadow: '0 0 5px rgb(59, 153, 252)',
    },
});
