import React, { useState } from 'react';
import styled from '@emotion/styled-base';
import Wishes from './Admin/Wishes';
import Button from './Admin/Button';
import Photos from './Admin/Photos';
export default function Admin(_a) {
    var pass = _a.pass;
    var _b = useState('wishes'), tab = _b[0], setTab = _b[1];
    return (<Layout>
      <h1>Vánoční radost</h1>
      <div css={{ display: 'flex', marginBottom: '3rem' }}>
        <Button onClick={function () { return setTab('wishes'); }} outlined={tab !== 'wishes'}>
          Seznam dárků
        </Button>
        <Button onClick={function () { return setTab('photos'); }} outlined={tab !== 'photos'}>
          Fotky
        </Button>
      </div>
      {tab === 'wishes' && <Wishes pass={pass}/>}
      {tab === 'photos' && <Photos pass={pass}/>}
    </Layout>);
}
var Layout = styled('div')({
    padding: '1.5rem',
    maxWidth: '980px',
    margin: '0 auto',
});
